import React, { useEffect, useMemo, useRef, useState } from 'react';
import "./style.less"
import moment from "moment";
import DatePicker from "react-datepicker";
import Select from "react-select";
import PriceRange from "../BudgetPickerFilter/PriceRange";
import {useTranslation} from "react-i18next";
import addCircle from "../../images/add.svg";
import SearchFilter from "../SearchFilter";
import DropdownSelect from '../Dropdown/Select';
import DropdownWindow from '../Dropdown/Window';
import SortAscendingIcon from '../svg/SortAscending';
import SortDescendingIcon from '../svg/SortDescending';
import AppliedFilters from './AppliedFilters';
import CompareArrowsIcon from '../svg/CompareArrows';
import TuneIcon from "../svg/Tune";
import { isMobile } from '../../utils/responsive';
import CustomDatePicker from "../CustomDatePicker";
import CalendarIcon from "../svg/Calendar";

const Filters = ({
                     filters,
                     isByAsc,
                     lastSort,
                     setIsByAsc,
                     sort,
                     handleFilter,
                     resetFilters,
                     type,
                     searchPlaceholder,
    setSearchFilter,
    setFilters
                 }) => {

    const { t, i18n } = useTranslation();
    const label = "main_page.radar.offers_filters";

    /* const [isMoveInDatesFilterOpen, setIsMoveInDatesOpen] = useState(false);
    const [filterByMoveOut, setIsMoveInDatesOpen] = useState(false); */

    const [isMoveInDatesFilterOpen, setIsMoveInDatesOpen] = useState(false);
    const [isMoveOutDatesFilterOpen, setIsMoveOutDatesOpen] = useState(false);
    const [isBudgetFilterOpen, setIsBudgetFilterOpen] = useState(false);
    const [filterBudgetValues, setFilterBudgetValues] = useState();
    const [moreFilters, setMoreFilters] = useState(false);

    const [appliedFilters, setAppliedFilters] = useState(() => {
        const { currentStatus, ...filteredFilters } = filters;
        return filteredFilters;
    });

    useEffect(() => {
        delete filters.currentStatus;
        //console.log("filters", filters)
        setAppliedFilters(filters);
      }, [filters]);

    const toggleBudgetFilter = () => {
        setIsBudgetFilterOpen(!isBudgetFilterOpen);
    }
    const toggleMoreFilters = () => setMoreFilters(!moreFilters);

    const removeFilter = (filterToRemove) => {
        let newFilters = { ...filters };
        delete newFilters[filterToRemove];
        setAppliedFilters(newFilters);
        // Update the filters object to remove the matching value
        handleFilter(filterToRemove, null);
        setFilters(newFilters);
    };

    const clearAllFilters = async () => {
        /* Clear all applied filters except the currentStatus */
        setFilters({currentStatus: filters.currentStatus});
        setAppliedFilters({currentStatus: filters.currentStatus});
        await resetFilters()
    };

    const isRadar = useMemo(() => {
        return type !== "reservation";
    }, []);

    const histogram = {
        histogram: {
            100: 0, 150: 1, 200: 1, 250: 2, 300: 4, 350: 5, 400: 7, 450: 8, 500: 10,
            550: 12, 600: 20, 650: 22, 700: 25, 750: 20, 800: 24, 850: 30, 900: 26,
            950: 20, 1000: 15, 1050: 18, 1100: 20, 1150: 12, 1200: 10, 1250: 8, 1300: 6,
            1350: 3, 1400: 4, 1450: 2, 1500: 0, 1550: 1, 1600: 2
        }, total: 3231
    };

    //TODO nao esta a mostrar a lisboa?
    const optionsByCity = [
        {value: 'lisboa', label: 'Lisboa'},
        {value: 'porto', label: 'Porto'},
        {value: 'evora', label: 'Évora'},
        {value: 'coimbra', label: 'Coimbra'},
        {value: 'braga', label: 'Braga'},
        {value: 'aveiro', label: 'Aveiro'},
        {value: 'barcelona', label: 'Barcelona'},
        {value: 'madrid', label: 'Madrid'},
        {value: 'milan', label: 'Milan'},
        {value: 'roma', label: 'Roma'},
        {value: 'sevilla', label: 'Sevilla'},
        { value: 'valencia', label: 'Valencia' }
    ]

    const optionsByAccommodation = [
        {value: 'rooms', label: t(label + '.bedrooms')},
        {value: 'apartment', label: t(label + '.apartment')},
        { value: 'residence', label: t(label + '.residence') }
    ]


    const optionsBySort = isRadar ? [
        {
            value: 'registrationDate',
            label: t(label + '.registrationDate')
        },
        { value: 'budgetMin', label: t(label + '.budgetMin') },
        { value: 'budgetMax', label: t(label + '.budgetMax') },
        { value: 'numRooms', label: t(label + '.numRooms') },
        { value: 'stayDates', label: t(label + '.stayTime') },
        { value: 'offers', label: t(label + '.numOffers') },
        { value: 'numTenants', label: t(label + '.numTenants') },
        { value: 'moveIn', label: t(label + '.moveIn') },
        { value: 'moveOut', label: t(label + '.moveOut') }
    ] : [
            { value: 'requestDate', label: t(label + '.requestDate') },
        {
            value: 'totalContractValue',
            label: t(label + '.totalContractValue')
        },
            { value: 'acceptedDate', label: t(label + '.acceptedDate') },
            { value: 'paymentDate', label: t(label + '.paymentDate') },
            { value: 'firstRent', label: t(label + '.firstRent') },
            { value: 'stayDates', label: t(label + '.stayTime') },
            { value: 'moveIn', label: t(label + '.moveIn') },
            { value: 'moveOut', label: t(label + '.moveOut') },

    ]

    const optionsByOccupation = ["worker", "student", "internship", "other"].map(occupation => ({
        value: occupation,
        label: t('main_page.my_visits.visit_card.occupation.' + occupation)
    }));

    const optionsByGender = [
        {value: 'male', label: t(label + '.male')},
        { value: 'female', label: t(label + '.female') }
    ]

    const metro_regions = {
        "lisboa": ['blue', 'yellow', 'red', 'green'],
        "porto": ['blue', 'yellow', 'red', 'green', 'orange', 'violet', 'pink'],
        "barcelona": ['1', '2', '3', '4', '5', '9n', '9s', '10n', '10s', '11', 'fm'],
        "madrid": ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', 'r', 'r1', 'r2', 'r3'],
        "milan": ['1', '2', '3', '9', '10', '12', '14', '15', '16', '13', '24', '27', 'M1', 'M2', 'M3', 'M4', 'M5'],
        "roma": ['A', 'B', 'C', '2', '3', '5', '8', '14', '19'],
        "sevilla": ['1'],
        "valencia": ['1', '2', '3', '4', '5', '6', '7', '8', '9']
    };

    const metroLines = useMemo(() => {
        let options = (metro_regions[filters.city || "lisboa"])?.map(option => ({
            value: option,
            label: ["barcelona", "madrid", "milan", "roma", "sevilla", "valencia"].includes(filters.city || "lisboa") ? option.toUpperCase() : t(label + '.metro_lines.' + option)
        })) || [];
        options.push({value: null, label: t(label + '.none')});
        return options;
    }, [filters.city]);

    const SimpleDatePicker = ({ close, onChange, selected }) => {
        const selectedDate = selected instanceof Date ? selected : new Date(selected);

        return (
            <div className="simple-datepicker-revamped">
                <DatePicker
                    onChange={onChange}
                    dateFormat="dd/MM"
                    /* value={selected} */
                    selected={selectedDate}
                    disabledKeyboardNavigation
                    locale={i18n.language}
                    formatWeekDay={nameOfDay => nameOfDay.charAt(0).toUpperCase()}
                    popperPlacement={'bottom-start'}
                    onClickOutside={() => close()}
                    dayClassName={date => 'passed_day'}
                    onKeyDown={(e) => {
                        e.preventDefault();
                    }}
                />
                <CalendarIcon />
            </div>

        )
    }

    return (
        <div className="filters">
            <div className="filters__options">
                <div className="filters__dropdowns">
                    <div className="filters__sort"
                        onClick={() => {
                            if (lastSort) setIsByAsc(!isByAsc);
                            sort(null, !isByAsc)
                        }}
                    >
                        {isByAsc ? <SortAscendingIcon /> : <SortDescendingIcon />}
                    </div>

                    <DropdownSelect placeholder={t(label + ".orderBy")}
                        options={optionsBySort}
                        handleChange={(e) => sort(e.value, isByAsc)}
                    />

                    {!isRadar && <>
                        <DropdownSelect placeholder={t(label + ".city")}
                            options={optionsByCity}
                            handleChange={(option) => handleFilter("region", option.value)}
                        />

                        <DropdownSelect placeholder={t(label + ".accommodation")}
                            options={optionsByAccommodation}
                            handleChange={(option) => handleFilter("accommodation", option.value)}
                        />

                        <DropdownSelect placeholder={t(label + ".numRooms")}
                            options={Array.from({ length: 10 }, (_, index) => ({
                                value: index + 1,
                                label: String(index + 1),
                            }))}
                            handleChange={(option) => handleFilter("numRooms", option.value)}
                        />
                    </>}

                    {/* TODO fechar dropdown após save */}
                    {isRadar && <DropdownWindow placeholder={t(label + ".budget")}>
                        <DropdownWindow.Content>
                            <PriceRange
                                chartData={histogram.histogram}
                                value={filters.budget ? filters.budget : [100, 1600]}
                                onChange={(e) => setFilterBudgetValues(e)}
                                range={[100, 1600]}
                                barStep={50}
                                step={50}
                            />
                            <button className="btn btn-primary"
                                onClick={() => { handleFilter("budget", filterBudgetValues); toggleBudgetFilter() }}
                            >
                                {t("buttons.save")}
                            </button>
                        </DropdownWindow.Content>
                    </DropdownWindow>}

                    <DropdownWindow placeholder={t(label + ".dates_selected_title")}>
                        <DropdownWindow.Content>
                            <div className="dates__filter">
                                <div className="datepicker-container">
                                    <label>{t(label + ".move_in")}</label>
                                    <SimpleDatePicker open={isMoveInDatesFilterOpen}
                                        close={() => setIsMoveInDatesOpen(false)}
                                        onChange={(data) => {
                                            handleFilter("moveIn", data.toISOString());
                                        }}
                                        selected={filters.moveIn ? new Date(filters.moveIn) : new Date()}
                                    />
                                </div>
                                <CompareArrowsIcon />
                                <div className="datepicker-container">
                                    <label>{t(label + ".move_out")}</label>
                                    <SimpleDatePicker open={isMoveOutDatesFilterOpen}
                                        close={() => setIsMoveOutDatesOpen(false)}
                                        onChange={(data) => {
                                            handleFilter("moveOut", data.toISOString());
                                        }}
                                        selected={filters.moveOut ? new Date(filters.moveOut) : new Date()}
                                    />
                                </div>
                            </div>
                        </DropdownWindow.Content>
                    </DropdownWindow>

                    {isRadar && <DropdownSelect placeholder={t(label + ".occupation")}
                        options={optionsByOccupation}
                        handleChange={(option) => handleFilter("occupation", option.value)}
                    />}

                </div>
                <div className="filters__more-options">
                    <div className={`dropdown__header`} onClick={toggleMoreFilters}>
                        <TuneIcon />
                        {!isMobile ? "More Filters" : "Filters"}
                    </div>
                    <SearchFilter onChange={(value) => setSearchFilter(value)}
                        placeholder={(searchPlaceholder ? searchPlaceholder : (!isRadar ? "property_placeholder" : "name_placeholder"))}
                    />
                </div>
            </div>

            <AppliedFilters appliedFilters={appliedFilters} removeFilter={removeFilter} clearAll={clearAllFilters} />

            {/* <div className={"filtersContainer"}>
                <div className={"filters afterLine"}>

                    <Select options={optionsByCity}
                            onChange={(o) => handleFilter("region", o.value)}
                            placeholder={filters.region ? t("cities." +  filters.region) : t(label + ".city")}/>
                    <Select options={optionsByAccommodation} onChange={(o) => handleFilter("accommodation", o.value)}
                            placeholder={filters.accommodation ? t("accommodation_type." + filters.accommodation) : t(label + ".accommodation")}/>
                    <div className={"filter"}>
                        <p onClick={() => setIsMoveInDatesOpen(true)}
                           onDoubleClick={() => handleFilter("moveIn", null)}>{(filters.moveIn ? filters.moveIn : t(label + ".move_in"))}</p>
                        <SimpleDatePicker open={isMoveInDatesFilterOpen} close={() => setIsMoveInDatesOpen(false)}
                                          onChange={(data) => {
                                              handleFilter("moveIn", new Date(data));
                                          }} selected={filters.moveIn ? new Date(filters.moveIn) : new Date()}/>
                    </div>
                    <div className={"filter"}>
                        <p onClick={() => setIsMoveInDatesOpen(true)}
                           onDoubleClick={() => handleFilter("moveOut", null)}>{(filters.moveOut ? filters.moveOut : t(label + ".move_out"))}</p>
                        <SimpleDatePicker open={isMoveInDatesFilterOpen} close={() => setIsMoveInDatesOpen(false)}
                                          onChange={(data) => {
                                              handleFilter("moveOut", new Date(data));
                                          }} selected={filters.moveOut ? new Date(filters.moveOut) : new Date()}/>
                    </div>
                    {isRadar && <div
                        className={"filter" + ((filters.budget && (filters.budget[0] !== 0 || (filters.budget[1] !== 0 && filters.budget[1] !== 1600))) ? " non-background" : "")}>
                        {(!filters.budget || (filters.budget[0] === 0 && (filters.budget[1] === 0 || filters.budget[1] === 1600))) &&
                            <p onClick={() => { setIsBudgetFilterOpen(true); }}>{t(label + ".budget")}</p>}
                        {isBudgetFilterOpen &&
                            <div className={"priceRangeFilter"} ref={budgetPopUp}>
                                <h4>{t(label + ".budget")}</h4>
                                <p>{t(label + ".medium", {num: 650})}</p>
                                <PriceRange
                                    chartData={histogram.histogram}
                                    value={filters.budget ? filters.budget : [100, 1600]}
                                    onChange={(e) => setFilterBudgetValues(e)}
                                    range={[100, 1600]}
                                    barStep={50}
                                    step={50}
                                />
                                <button className={'btn btn_blue'}
                                        onClick={() => {
                                            setIsBudgetFilterOpen(false);
                                            handleFilter("budget", filterBudgetValues)
                                        }}>{t("buttons.save")}</button>
                            </div>}
                        {((filters.budget && (filters.budget[0] !== 0 || (filters.budget[1] !== 0 && filters.budget[1] !== 1600)))) &&
                            <div className={"prices-filters"} onClick={() => {
                                setIsBudgetFilterOpen(false);
                                handleFilter("budget", [0, 1600]);
                            }}>
                                <input value={"€" + (filters.budget ? filters.budget[0] ? filters.budget[0] : 0 : 0)}
                                       readOnly={true}/>
                                <input value={"€" + (filters.budget ? filters.budget[1] ? filters.budget[1] : 0 : 0)}
                                       readOnly={true}/>
                            </div>}
                    </div>}
                    <div className={"more_filters"} onClick={() => setMoreFilters(!moreFilters)}>
                        <img className={"sortIcon"} src={addCircle} alt={"more filters"}/>
                        <p>{t(label + ".moreFilters")}</p>
                    </div>
                </div>
                <div className={"sort"}>
                    <img onClick={() => {
                        if (lastSort) setIsByAsc(!isByAsc);
                        sort(null, !isByAsc)
                    }}
                         className={"sortIcon" + (!isByAsc ? "" : " asc")} src={FilterList}
                         alt={isByAsc ? "Sort Asc" : "Sort Desc"}/>
                    <p>{t(label + ".orderBy")}</p>
                    <Select options={optionsBySort} onChange={(e) => sort(e.value, isByAsc)} className={"sort__select"}
                        placeholder={lastSort ? (t(label + '.' + lastSort)) : t(label + '.selectOption')} />
                </div>
            </div>
            {moreFilters &&
                <div className={"filtersContainer"}>
                    <div className={"filters moreFiltersContainer"}>
                        {isRadar && <input
                            type={"number"}
                            className={"filter"}
                            inputMode={"number"}
                            name={"price"}
                            placeholder={filters.numRooms ? filters.numRooms : t(label + ".numRooms")}
                            onKeyDown={e => e.key === "Enter" && handleFilter("numRooms", e.target.value)}
                        />}
                        {isRadar && <Select options={optionsByNeighborhood || []}
                                                      onChange={(o) => handleFilter("neighborhood", o.value)}
                                                      placeholder={filters.neighborhood ? filters.neighborhood : t(label + ".neighborhood")}/>}
                        {isRadar &&
                            <Select options={metroLines} onChange={(o) => handleFilter("metro", o.value)}
                                    placeholder={filters.metro ? (["barcelona", "madrid", "milan", "roma", "sevilla", "valencia"].includes(filters.city || "lisboa") ? filters.metro.toUpperCase() : t(label + '.metro_lines.' + filters.metro)) : t(label + ".metro")}/>}
                        {isRadar && <Select options={optionsByUniversity || []}
                                                      onChange={(o) => handleFilter("university", o.value?.split(" ")?.[0])}
                                                      placeholder={filters.university ? filters.university : t(label + ".university")}/>}
                        {isRadar &&
                            <Select options={optionsByNationality || []}
                                    onChange={(o) => handleFilter("nationality", o.value)}
                                    placeholder={filters.nationality ? t("country." + filters.nationality) : t(label + ".nationality")}/>}
                        {isRadar &&
                            <Select options={optionsByOccupation} onChange={(o) => handleFilter("occupation", o.value)}
                                    placeholder={filters.occupation ? t(label + '.' + filters.occupation) : t(label + ".occupation")}/>}
                        {isRadar &&
                            <Select options={optionsByGender} onChange={(o) => handleFilter("gender", o.value)}
                                    placeholder={filters.gender ? t(label + '.' + filters.gender) : t(label + ".gender")}/>}
                    </div>
                    <div>
                        <PropertySearchFilterInput onChange={(value) => setSearchFilter(value)} placeholder={(filters.searchKey ? filters.searchKey : (!isNotReservations ? "property_placeholder" : "name_placeholder"))}/>
                    </div>
                </div>} */}
        </div>
    )
}

export default Filters;
